* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

a {
    text-decoration: none;
    color: #0C96D7;
}

html {
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
}
@media (max-width: 800px) {
    html {
        font-size: 14px;
    }
}

body {
    background-color: #333;
    overflow-x: hidden;
}
